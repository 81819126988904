<template>
  <div class="space-y-2 overflow-hidden transition-all">
    <USkeleton class="h-4 w-0 skeleton-animation" />
    <USkeleton class="h-4 w-0 skeleton-animation-delay-100" />
    <USkeleton class="h-4 w-0 skeleton-animation-delay-200 max-w-[33%]" />
  </div>
</template>

<script lang="ts" setup></script>

<style>
@keyframes grow {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

.skeleton-animation {
  animation:
    grow 1s ease-in-out forwards,
    pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.skeleton-animation-delay-100 {
  animation:
    grow 1s ease-in-out 100ms forwards,
    pulse 2s cubic-bezier(0.4, 0, 0.6, 1) 100ms infinite;
}

.skeleton-animation-delay-200 {
  animation:
    grow 1s ease-in-out 200ms forwards,
    pulse 2s cubic-bezier(0.4, 0, 0.6, 1) 200ms infinite;
}
</style>
